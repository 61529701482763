import React, { useState } from "react";
import { RichTextEditor } from "../../../helpers";
import { Button, H2, Popup, PopupHeader, Row, XIcon } from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../../popup-factory";
import { useShopById, useUpdateShopById } from "../../../../redux/hooks/shops";
import { useDispatch } from "react-redux";
import { closePopup } from "../../../../actions/popup";
import "./EditHelpScreenTextPopup.css";

interface EditHelpScreenTextPopupProps {
  shopId: string;
}

const EditHelpScreenTextPopup = ({ shopId }: EditHelpScreenTextPopupProps) => {
  const dispatch = useDispatch();
  const shop = useShopById(shopId);
  const [helpText, setHelpText] = useState<string>(shop?.introduction || "");
  const onUpdateShop = useUpdateShopById(shopId);

  const onPopupClose = () => dispatch(closePopup());
  return (
    <Popup
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      style={{ width: 896, height: 494, borderRadius: 10 }}
      header={
        <PopupHeader
          style={{
            padding: 0,
            alignItems: "center",
            marginBottom: 16,
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          <H2 style={{ margin: "0px" }} className="title">
            Help Screen Text
          </H2>
          <XIcon
            style={{ cursor: "pointer" }}
            size="large"
            onClick={() => onPopupClose()}
          />
        </PopupHeader>
      }
    >
      <div className="editHelpScreenPopupEditor">
        <RichTextEditor
          value={helpText}
          onChange={(value: React.SetStateAction<string>) => setHelpText(value)}
          onBlur={(value: React.SetStateAction<string>) => setHelpText(value)}
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 16,
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Button variant="secondary" onClick={onPopupClose} size="medium">
            Cancel
          </Button>
          <Button
            variant={"primary"}
            disabled={shop.introduction === helpText}
            onClick={() => {
              onUpdateShop("introduction", shop.introduction, helpText);
              onPopupClose();
            }}
            size="medium"
            style={{ width: 135 }}
          >
            Done
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default EditHelpScreenTextPopup;
